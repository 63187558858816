import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import axios from '../axios';
import services from '../services';
import { getUserDataWithUsername, IUserProps } from '../common/data/userDummyData';

export interface IAuthContextProps {
	user: string;
	setUser?(...args: unknown[]): unknown;
	userData: Partial<IUserProps>;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode;
}
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	const [user, setUser] = useState<string>(sessionStorage.getItem('token') || '');
	const [userData, setUserData] = useState<Partial<IUserProps>>({});
	const logout = async () => {
		try {
			const resp = await services.auth.logout();
			if (resp.data.statcode === 0) {
				sessionStorage.setItem('token', '');
				window.location.href = '/auth-pages/login';
			}
		} catch (error: any) {
			if (error.response.status === 401) {
				console.log(error);
			}
		}
	};

	useEffect(() => {
		sessionStorage.setItem('token', user);
	}, [user]);

	// useEffect(() => {
	// 	if (user !== '') {
	// 		(async () => {
	// 			try {
	// 				const resp = await axios.get('/user');
	// 				if (resp.status === 200) {
	// 					setUserData(resp.data.data);
	// 				}
	// 			} catch (error: any) {
	// 				if (error.response.status === 401) {
	// 					sessionStorage.setItem('token', '');
	// 					setUserData({});
	// 					window.location.href = '/auth-pages/login';
	// 				}
	// 			}
	// 		})();
	// 	} else {
	// 		setUserData({});
	// 	}
	// }, [user]);

	useEffect(() => {
		let inactivityTimer: NodeJS.Timeout;

		const resetInactivityTimer = () => {
			clearTimeout(inactivityTimer);
			if (user) {
				inactivityTimer = setTimeout(
					() => {
						logout();
					},
					10 * 60 * 1000,
				); // 10 menit tanpa aktivitas
			}
		};

		// Event untuk mendeteksi interaksi pengguna
		window.addEventListener('mousemove', resetInactivityTimer);
		window.addEventListener('keydown', resetInactivityTimer);
		window.addEventListener('scroll', resetInactivityTimer);
		window.addEventListener('click', resetInactivityTimer);

		return () => {
			// Bersihkan timer dan event listeners saat komponen unmount
			clearTimeout(inactivityTimer);
			window.removeEventListener('mousemove', resetInactivityTimer);
			window.removeEventListener('keydown', resetInactivityTimer);
			window.removeEventListener('scroll', resetInactivityTimer);
			window.removeEventListener('click', resetInactivityTimer);
		};
	});

	const value = useMemo(
		() => ({
			user,
			setUser,
			userData,
		}),
		[user, userData],
	);
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
